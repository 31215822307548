<template>
  <div>
    <el-image v-if="banner" class="w100" style="display: block;" :src="banner"></el-image>

    <div class="w100 technical-tab">
      <ul>
        <li :class="{'active': type == item.value}" v-for="(item, i) in types" :underline="false" @click="changeType(item.value, item[lang + 'name'])">{{item[lang + 'name']}}</li>
      </ul>
    </div>

    <div class="container" v-if="type == 'stockInfo'">
      <div class="stockInfo">
        <h2>证券信息</h2>

        <el-row :gutter="30" class="stockInfo-warp" type="flex" justify="center">
          <el-col :span="6" v-for="(list, index) in stockList">
            <a :class="{'active': stockType == list.value}" @click="getNewChart(list.value)">{{list.name}}</a>
          </el-col>
        </el-row>

        <div class="subStock" v-if="stockInfo">
          <div class="subStock02 clearfix">
            <div class="subStock02_L">
              <p class="p01" style="color: green;">
                <span class="_gujia">{{stockInfo[3]}}</span>
                <!--<img src="/images/green.jpg" alt="" width="25" height="31">-->
              </p>
              <p class="p02" style="color: green;">
                <span class="_gujia_1">{{stockInfo[31]}}1%</span>
                <span class="_gujia_2">{{stockInfo[32]}}</span>
              </p>
              <p class="p03"><span class="_gujia_time">{{time}}</span></p>
            </div>
            <div class="fl">
              <ul class="subStock02Ul">
                <li>
                  昨收:
                  <b class="_gujia_zuoshou">{{stockInfo[4]}}</b>
                </li>
                <li>
                  今开:
                  <b class="_gujia_jinkai">{{stockInfo[5]}}</b>
                </li>
                <li>
                  最高:
                  <b class="_gujia_zuigao">{{stockInfo[41]}}</b>
                </li>
                <li>
                  最低:
                  <b class="_gujia_zuidi">{{stockInfo[42]}}</b>
                </li>
              </ul>
              <ul class="subStock02Ul">
                <li>
                  成交量:
                  <b class="_gujia_chenjiaoliang">{{stockInfo[36]}}手</b>
                </li>
                <li>
                  成交额:
                  <b class="_gujia_chenjiaoe">{{stockInfo[37]}}万</b>
                </li>
                <li>
                  总市值:
                  <b class="_gujia_zongshizhi">{{stockInfo[45]}}亿</b>
                </li>
                <li>
                  流通市值:
                  <b class="_gujia_liutongshizhi">{{stockInfo[44]}}亿</b>
                </li>
              </ul>
              <ul class="subStock02Ul">
                <li>
                  换手率:
                  <b class="_gujia_huanshoulv">{{stockInfo[38]}}%</b>
                </li>
                <li>
                  市净率:
                  <b class="_gujia_shijinglv">{{stockInfo[46]}}</b>
                </li>
                <li>
                  振幅:
                  <b class="_gujia_zhengfu">{{stockInfo[43]}}%</b>
                </li>
                <li>
                  市盈率:
                  <b class="_gujia_shiyinglv">{{stockInfo[39]}}</b>
                </li>
              </ul>
            </div>
            <div class="clean"></div>
          </div>
        </div>

        <div class="stockInfo-img">
          <img :class="{'active': stockType == list.value}" :src="`http://image.sinajs.cn/newchart/${list.value}/n/${stockCode}.gif`" v-for="(list, index) in stockList">
        </div>
      </div>
    </div>

    <div class="container" v-if="type == 'info'">
      <h3 class="info">基本资料</h3>
      <div class="attr">
        <div class="inline-block">证券简称：<span class="loc">金太阳</span></div>
        <div class="inline-block">证券代码：<span class="loc">300606</span></div>
      </div>
      <el-table
          class="info-attr"
          :data="info.info"
          stripe
          border
          :show-header="false"
          style="width: 100%">
        <el-table-column
            :prop="lang + 'title'"
            align="right"
            :label="lang + 'title'">
        </el-table-column>
        <el-table-column
            :prop="lang + 'content'"
            :label="lang + 'content'">
        </el-table-column>
      </el-table>

      <div v-html="info[lang + 'content']"></div>
    </div>

    <div class="container" v-if="type == 'investorContact'">
      <h3 class="info">投资者保护与联络</h3>
      <el-table
          class="info-attr"
          :data="info.info"
          stripe
          border
          :show-header="false"
          style="width: 100%">
        <el-table-column
            :prop="lang + 'title'"
            align="right"
            :label="lang + 'title'">
        </el-table-column>
        <el-table-column
            :prop="lang + 'content'"
            :label="lang + 'content'">
        </el-table-column>
      </el-table>

      <div v-html="info[lang + 'content']"></div>
    </div>

    <div class="container mt20" v-if="type == 'companyReport' || type == 'periodicReport' || type == 'investorEducation'  || type == 'corporateReport'">
      <!--<el-row :gutter="20" type="flex" justify="start" class="flex-wrap mt20" v-loading="loading">
        <el-col
            :xs="12"
            :sm="12"
            :md="12"
            :lg="6"
            :xl="6" v-for="(item, i) in list" :key="item.id">

          <div class="wrap_content_item_box">
            <div @click="goto(item.content, item.type)" v-if="item.type == 'companyReport' || item.type == 'periodicReport' || item.type == 'investorEducation'  || item.type == 'corporateReport'">
              <el-image style="width: 100%; height: 180px;" fit="cover" :src="item.thumbnail"/>
              <div class="wrap_content_item_box_card">
                <h3 class="title">{{item[lang + 'title']}}</h3>
                <p class="desc">{{item[lang + 'desc']}}</p>
              </div>

              <div class="entry-content">
                <span>{{item.create_time}}</span>
              </div>
            </div>

            <div v-else  @click="gotoPost(item.id)">
              <el-image style="width: 100%; height: 180px;" :src="item.thumbnail"/>
              <div class="wrap_content_item_box_card">
                <h3 class="title">{{item[lang + 'title']}}</h3>
                <p class="desc">{{item[lang + 'desc']}}</p>
              </div>

              <div class="entry-content">
                <span>{{item.create_time}}</span>
              </div>
            </div>

          </div>
        </el-col>
      </el-row>-->

      <el-table
          class="investor-list flex-wrap mt20 mb20"
          :data="list"
          v-loading="loading">
        <el-table-column
            width="140px">
          <template #header>
            <h2 class="color-green">{{title}}</h2>
          </template>
          <template v-slot="scope">
            <div class="list-time">
              <span class="date">
                <a>{{scope.row.date_date}}</a>
              </span>
              <span>{{scope.row.date_year + '-' + scope.row.date_month}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template v-slot="scope">
            <span class="investor-list-title">{{scope.row[lang + 'title']}}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="120px"
            align="right">
          <template #header>
            <el-date-picker
                style="width: auto !important;"
                class="select-year"
                v-model="year"
                size="small"
                type="year"
                value-format="yyyy"
                :placeholder="$t('page.investor.year')"
                @change="get">
            </el-date-picker>
          </template>
          <template v-slot="scope">
            <el-button
                size="mini"
                type="danger"
                style="background-color: #00923F;border-bottom: 2px solid #00923F;border-color: #00923F;"
                @click="goto(scope.row.content, scope.row.type)">{{$t('page.investor.view_pdf')}}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
          v-if="type != 'stockInfo' && type != 'info'"
          class="mb20 line"
          background
          :hide-on-single-page="onePage"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          @size-change="get"
          @current-change="get"
          layout="prev, pager, next">
      </el-pagination>
    </div>

  </div>
</template>


<script>
import config from '@/config';
import axios from 'axios';

import {getPosts, getOption, getStockInfo} from "@/api/os/admin";

export default {
  data() {
    return {
      year: '',
      title: '',
      optionName: 'page-banner',
      infoOptionName: 'page-investor',
      banner: '',
      type: config.default.investorType[0].value,
      types: config.default.investorType,
      currentPage: 0,
      pageSize: config.default.pageLimit,
      // pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,
      list: [],
      // 股票代码
      stockType: 'min',
      stockCode: 'sz300606',
      stockInfo: '',
      stockList: [
        {
          name: '分时图',
          value: 'min',
        },
        {
          name: '日K线',
          value: 'daily',
        },
        {
          name: '周K线',
          value: 'weekly',
        },
        {
          name: '月K线',
          value: 'monthly',
        }
      ],

      info: {},

      timer: null  // 定时器名称
    }
  },
  created() {
    if(this.$route.params.type) {
      this.type = this.$route.params.type
    }
    if(this.type == 'stockInfo') {
      this.getStockInfo()
    }else if(this.type == 'info') {
      this.getInfo()
    }else {
      this.get()
    }
    this.getBanner()
  },
  mounted() {
    // setInterval(() => {
    //   this.getStockInfo()
    // }, 5000);

    this.timer = setInterval(() => {     // 某些操作
      this.getStockInfo()
    }, 5000)
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
    time() {
      let times = this.stockInfo[30];
      let time1 = (times.substring(4, 6) + "-" + times.substring(6, 8) + "  " + times.substring(8, 10) + ":" + times.substring(10, 12) + ":" + times.substring(12, 14))

      return time1;
    }
  },
  methods: {
    getInfo() {
      getOption({
        name: this.infoOptionName,
      }).then(res => {
        if(res.data.data) {
          this.info = res.data.data
        }
      })
    },
    getBanner() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.banner = res.data.data.investor
        }
      })
    },
    get() {
      this.loading = true
      getPosts({
        page: this.currentPage,
        limit: this.pageSize,
        type: this.type,
        year: this.year,
        orderType: 'create_time'
      }).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    changeType(type, title = '') {
      this.type = type
      this.title = title
      if(type == 'stockInfo') {
        return this.getStockInfo()
      }else if(this.type == 'info') {
        this.infoOptionName = 'page-investor'
        return this.getInfo()
      } else if( this.type == 'investorContact') {
        this.infoOptionName = 'page-investorContact'
        return this.getInfo()
      }
      this.get()
    },
    getStockInfo() {
      // getStockInfo().then(res => {
      //   if(res.data) {
      //     let data = res.data.split('~')
      //
      //     debugger
      //   }
      // })
      return new Promise(resolve => {
        axios.get('https://qt.gtimg.cn', {
          params: {
            q: this.stockCode
          }
        }).then(res => {
          let data = res.data.split('~')
          this.stockInfo = data
          // this.page++
          // console.log(this.page)
          resolve(res.data)
        }).catch(error => {
          console.log(error);
        })
      })
    },
    getNewChart(type) {
      this.stockType = type
    },
    gotoPost(id) {
      this.$router.push({
        name: 'post',
        params: {
          id: id,
          type: this.type
        }
      })
    },
    goto(url, type = '') {
      if(url) {
        if(type == 'periodicReport' || type == 'investorEducation' || type == 'corporateReport') {
          url = config.baseUrl.dev + url
        }
        // window.location = url
        window.open(url, '_blank');
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭
    }  //利用vue的生命周期函数
  }
}
</script>

<style lang="less" scoped>
.active{
  color: #00923F;
  border-bottom: 2px solid #00923F;
}
.technical-tab {
  background: #eeeeee;
  ul{
    displey:-webkit-flex;
    display: flex;
    -webkit-flex-flow:row nowrap; //设置排列方式为横向排列，并且超出元素不换行
    flex-flow:row nowrap;
    overflow-x: auto;
    white-space:nowrap;
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    li{
      cursor: pointer;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      margin-right: 60px;
      font-size: 16px;
    }
  }
}
.technical-warp{
  max-width: 1200px;
  margin: auto;
}
.wrap_content_item_box{
  border: 1px solid #e6ebf5;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.05);
  margin-bottom: 20px;
  cursor: pointer;
  .wrap_content_item_box_card{
    .title{
      height: 52px;
      margin: 10px;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .desc{
      font-size: .8rem;
      height: 52px;
      margin: 10px;
      color: #A2A2A2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  .entry-content{
    margin: 10px;
    color: #B3B3B3;
  }
}

.stockInfo{
  margin: 20px;
  h2{
    font-size: 26px;
    text-align: center;
  }
  .stockInfo-warp{
    width: 600px;
    margin: 30px auto !important;
    a{
      width: 100%;
      line-height: 48px;
      color: #666;
      border: 1px solid #666;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
      display: inline-block;
    }
    a.active{
      color: #fff;
      background: #00923F;
    }
  }

  .subStock {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .subStock02 {
      display: flex;
      align-items: center;
      .subStock02_L {
        float: left;
        width: 183px;
        padding-top: 10px;
        ._gujia_2{
          margin-left: 10px;
        }
        p.p01, p.p01 * {
          font-size: 30px;
          font-weight: bold;
          color: #b1020c;
          text-align: center;
        }
        p.p02, p.p02 * {
          text-align: center;
          font-size: 15px;
          color: #b1020c;
          padding-top: 5px;
        }
        p.p03, p.p03 * {
          text-align: center;
          color: #333;
        }
        img {
          margin-left: 15px;
          position: relative;
          top: 2px;
        }
      }
      div.fl {
        padding-top: 11px;
        .subStock02Ul {
          padding-left: 17px;
          font-size: 14px;
          color: #333;
          overflow: hidden;
          width: 535px;
          margin-bottom: 2px;
          li {
            float: left;
            white-space: nowrap;
            width: 25%;
          }
        }
      }
    }
  }
}
.stockInfo-img{
  img{
    display: none;
  }
  img.active{
    display: block;
    margin: 20px auto;
    width: 80%;
  }
}
.info{
  font-size: 26px;
  text-align: center;
  margin-top: 30px;
}
.attr{
  text-align: center;
  font-size: 18px;
  line-height: 28px;
  margin: 20px auto;
  .inline-block{
    display: inline-block;
  }
  .loc {
    color: #d0121b !important;
    margin-right: 10px;
  }
}
.info-attr{
  margin: 20px auto;
}
.investor .attr {
  margin: 20px auto 40px;
}
.text-center{
  text-align: center;
}

.list-time{
  text-align: center;
  font-size: 14px;
  span{
    display: block;
  }
  .date{
    font-size: 26px;
    a{
      border-bottom: 3px solid #00923F;
      padding: 6px 0;
      display: inline-block;
      font-weight: bold;
    }
  }
}

.investor-list-title{
  color: #000000;
  font-size: 20px;
}

/* maxWidth <=768 */
@media screen and (max-width: 768px){
  .investor-list-title{
    font-size: 14px;
  }
  .wrap_content_item_box{
    .el-image{
      height: 130px !important;
    }
    .wrap_content_item_box_card{
      .title{
        height: 36px;
        font-size: 14px;
        line-height: 18px;
      }
      .desc{
        height: 36px;
        font-size: 12px;
      }
    }
  }
}
</style>